<template>
    <router-view />
</template>

<script>
export default {
    name: 'ControlIndex',
};
</script>

<style scoped>
</style>
